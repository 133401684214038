import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';

@Component({
  selector: 'app-found-app',
  templateUrl: './found-app.component.html',
  styleUrls: ['./found-app.component.css']
})
export class FoundAppComponent implements OnInit {
  maskedUserEmail: any = "";
  inputEmail: string = "";
  userEmail: any = '';
  formEmailChecked = false;
  constructor(private router: Router,@Inject(LOCAL_STORAGE) private localStorage: any,) { }

  ngOnInit() {
    this.userEmail = this.localStorage.getItem('email');
    if (this.userEmail) {
      this.maskedUserEmail = this.maskUserEmail(this.userEmail);
    }
    console.log(localStorage.getItem('previousData'));
    if(localStorage.getItem('previousData') == null) {
      console.log('hello world');
      this.router.navigate(['/apply']);
    }
  }

  maskUserEmail(email: string) {
    const [localPart, domainPart] = email.split("@");

    const visbleCars = 2;
    const maskedLocalPart =
      localPart.slice(0, visbleCars) +
      "*".repeat(localPart.length - visbleCars);
    const maskedEmail = `${maskedLocalPart}@${domainPart}`;

    return maskedEmail;
  }

  clickProcess() {
    if (this.inputEmail.trim() !== this.userEmail) {
      return (this.formEmailChecked = true);
    } else {
      this.router.navigate(["/application"]);
    }
  }
}
