<app-nav></app-nav>
<br>
<br>
<br>
<br>
<main class="page page-contact-us">

  <!-- <aside class="header-image-half"></aside> -->

  <section class="container content-section">
    <div class="card p-5 mt-5">


      <div class="mt-5">
        <div *ngIf="status == 'Paid'">
          <div class="d-flex flex-row align-items-center mb-3">
            <i style="font-size:100px;color: #2ecc71;" class="fa fa-check-circle"></i>
            <h2 class="ml-4" style="color:#2DCB71;" translate>Payment Successful</h2>
          </div>
        </div>
        <br>
        <div *ngIf="status == 'UnPaid'">
          <div class="d-flex flex-row align-items-center mb-3">
            <i style="font-size:100px;color:#e74c3c;" class="fa fa-times-circle"></i>
            <h2 class="ml-4" style="color: #e74c3c;">Unpaid</h2>
          </div>

        </div>
        <div *ngIf="status == 'NotFound'">
          <div class="d-flex flex-row align-items-center mb-3">
            <i style="font-size:100px;color:#FFC500;" class="fa fa-exclamation-triangle"></i>
            <h2 class="ml-4" style="color:#FFC500;">Invoice Not Found</h2>
          </div>
        </div>
        <div *ngIf="status == 'Cancelled'">
          <div class="d-flex flex-row align-items-center mb-3">
            <i style="font-size:100px;color:#FFC500;" class="fa fa-exclamation-triangle"></i>
            <h2 class="ml-4" style="color:#FFC500;">Invoice is cancelled</h2>
          </div>
        </div>
        <div *ngIf="status == 'DECLINE'">
          <div class="d-flex flex-row align-items-center mb-3">
            <i style="font-size:100px;color:#FFC500;" class="fa fa-exclamation-triangle"></i>
            <h2 class="ml-4" style="color:#FFC500;">Payment is
              <span *ngIf="isCancelled; else declinedBlock">cancelled</span>
              <ng-template #declinedBlock><span>declined</span></ng-template>
            </h2>
          </div>
        </div>
        <div *ngIf="status == 'CANCEL'">
          <div class="d-flex flex-row align-items-center mb-3">
            <i style="font-size:100px;color:#e74c3c;" class="fa fa-times-circle"></i>
            <h2 class="ml-4" style="color: #e74c3c;">Payment is cancelled</h2>
          </div>
        </div>

      </div>
      <div>
        <p *ngIf="app.SelectedLang == 'en'" class="text-dark" [innerHTML]="message"></p>
        <p *ngIf="app.SelectedLang == 'cn'" class="text-dark" [innerHTML]="message_cn"></p>
        <p *ngIf="app.SelectedLang == 'fr'" class="text-dark" [innerHTML]="message_fr"></p>
      </div>

      <br>
      <div *ngIf=" status=='UnPaid' || status=='DECLINE' || status=='CANCEL'">
        <div>
          Please try again:
        </div>
        <br>
        <div class="row">
          <app-paynow [invoiceId]="invoice_id" payFrom="bank_callback"></app-paynow>
        </div>
      </div>
      <br>

      <p>
        <strong *ngIf="status == 'Paid'"><span translate="pay_success_1">Your application is being processed, and the
            processing time is 3 business days. If any information is missing, incorrect, or
            incomplete, the processing time may be extended, and you will need to update your details to complete the
            application</span></strong><br>
        <br>

        <strong *ngIf="status == 'Paid'" style="color: red;"><span>
            Important: For the avoidance of doubt, an <a href="/approval-letter" class="approval">eVisa approval letter</a> must be obtained prior to
            traveling to the Lao PDR. Failure to
            present the eVisa approval letter at the <a href="/port-entry" class="port" style="color:#136ee6">designated port of entry</a>  will result in entry being denied. In such
            case, you may be
            required to apply for a Visa On Arrival at the applicable port at your own expense and eVisa fee is
            non-refundable.
          </span></strong>
          <br>
          <br>
          <span >For more information, please contact our customer support team at
            <strong>support@laoevisa.gov.la</strong></span>
         <!-- <span *ngIf="app.SelectedLang == 'en'">For future inquiries please contact eVisa support center at -->
         <!-- <strong>support@laoevisa.gov.la</strong></span> -->
         <!-- <span *ngIf="app.SelectedLang == 'cn'">如有其他疑问，请通过 support@laoevisa.gov.la 与我们的工作人员联系</span> -->
         <!-- <span *ngIf="app.SelectedLang == 'fr'">For future inquiries please contact eVisa support center at -->
         <!-- <strong>support@laoevisa.gov.la</strong></span> -->
      </p>
      <!-- <p translate="pay_success_2"> -->
      <!-- The eVisa fee will not be refunded under any circumstances. -->
      <!-- </p> -->
    </div>
  </section>

</main>
<app-footer></app-footer>