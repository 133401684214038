import {
  Component,
  OnInit,
  Input,
  Output,
  AfterViewInit,
  Inject,
  PLATFORM_ID,
} from "@angular/core";
import { AppComponent } from "src/app/app.component";
import { environment } from "src/environments/environment";
import { Person } from "src/app/models/formData";
import { RestService } from "src/app/services/rest.service";
import { FormDataService } from "src/app/services/form-data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { EventEmitterService } from "src/app/services/event-emitter.service";
import { CountCartService } from "src/app/services/count-cart.service";
import * as $ from "jquery";
import offside from "offside-js";
import { isPlatformBrowser } from "@angular/common";
import { LOCAL_STORAGE, WINDOW } from "@ng-toolkit/universal";

@Component({
  selector: "app-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.css"],
})
export class NavComponent implements OnInit, AfterViewInit {
  isBrowser: boolean;
  person: Person;
  mLanguage: any;
  countCart = 0;
  haveToken = false;
  agentToken = false;
  button_lang: any;
  showFoundApp: any = false;
  // NavSelectedLang: string;
  // MySelectedLang: string;
  constructor(
    public app: AppComponent,
    private formDataService: FormDataService,
    public rest: RestService,
    private eventEmitterService: EventEmitterService,
    public countInCart: CountCartService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) platformId
  ) {
    // this.MySelectedLang = app.SelectedLang;
    // this.button_lang = app.SelectedLang;
    // this.NavSelectedLang = app.SelectedLang;
    this.button_lang = app.SelectedLang;
    this.isBrowser = isPlatformBrowser(platformId);
  }
  @Input() myFunction: Function;
  switchLanguage(language: string) {
    // this.NavSelectedLang = language;
    // this.MySelectedLang = language;
    this.button_lang = language;
    this.app.switchLanguage(language);
    if (this.router.url === "/term") {
      this.TermConditionsComponentFunction(language);
    } else if (this.router.url === "/faq") {
      this.FAQComponentFunction(language);
    } else if (this.router.url === "/info") {
      this.InfoComponentFunction(language);
    } else if (this.router.url === "/about") {
      this.AboutComponentFunction(language);
    } else if (this.activatedRoute.snapshot.url[0].path === "article") {
      this.ArticleComponentFunction(language);
    }
  }
  ngOnInit() {
    this.getLanguage();
    if(localStorage.getItem('previousData') !== null && localStorage.getItem('localAuthenInfo') !== null){
      this.showFoundApp = true;
    }
    this.person = this.formDataService.getFormData();
    if (localStorage.getItem(environment.localAuthenInfo) != null) {
      const authInfo = JSON.parse(
        localStorage.getItem(environment.localAuthenInfo)
      );
      if (authInfo) {
        if (authInfo.token) {
          this.haveToken = true;
          this.getCountCart();
        }
      }
    }

    // Check agent login
    if (localStorage.getItem(environment.agentAuthenInfo) != null) {
      const authInfo = JSON.parse(
        localStorage.getItem(environment.agentAuthenInfo)
      );
      if (authInfo) {
        if (authInfo.token) {
          this.agentToken = true;
        }
      }
    }
    if (this.isBrowser) {
      offside(".navbar-menu-mobile-wrapper", {
        buttonsSelector: ".navbar-menu-toggle",
        slidingSide: "right",
      });
    }

    // THIS MAKE INPUT CLICKABLE ON MOBILE
    function toggleInputClickableIfOverlayByLogo(width) {
      const logoEle = $(".navbar-evisa-logo");
      if (width >= 1800) {
        logoEle.removeClass("child-clickable");
      } else {
        logoEle.addClass("child-clickable");
      }
    }
    if (
      this.router.url === "/application" ||
      this.router.url.split("/")[1] === "edit-application"
    ) {
      $(document).ready(async function () {
        toggleInputClickableIfOverlayByLogo($(this.window).width());
      });
    }

    if (
      this.router.url === "/application" ||
      this.router.url.split("/")[1] === "edit-application"
    ) {
      $(window).on("resize", function () {
        toggleInputClickableIfOverlayByLogo($(this).width());
      });
    }
  }
  ngAfterViewInit(): void {}

  public async getCountCart() {
    await this.countInCart.funcCountCart();
  }

  TermConditionsComponentFunction(language: string) {
    this.eventEmitterService.onTermConditionComponentButtonClick(language);
  }
  FAQComponentFunction(language: string) {
    this.eventEmitterService.onFAQComponentButtonClick(language);
  }
  InfoComponentFunction(language: string) {
    this.eventEmitterService.onInfoComponentButtonClick(language);
  }

  AboutComponentFunction(language: string) {
    this.eventEmitterService.onAboutComponentButtonClick(language);
  }
  ArticleComponentFunction(language: string) {
    this.eventEmitterService.onArticleComponentButtonClick(language);
  }
  getLanguage() {
    this.rest.getLanguage().subscribe(async (result) => {
      this.mLanguage = await result;
    });
  }
  goIndex() {
    // offside('.navbar-menu-mobile-wrapper', {
    //   buttonsSelector: '.navbar-menu-toggle',
    //   slidingSide: 'right'
    // });
    this.router.navigate(["/index"], { replaceUrl: true });
  }

  logout() {
    localStorage.removeItem(environment.localAuthenInfo);
    localStorage.removeItem(environment.agentAuthenInfo);
    localStorage.removeItem("confirm");
    this.agentToken = false;
    this.router.navigate(["/login"]);
  }
}
