import { Component, OnInit } from '@angular/core';
import { RestService } from "src/app/services/rest.service";

@Component({
  selector: 'app-port-entry',
  templateUrl: './port-entry.component.html',
  styleUrls: ['./port-entry.component.css']
})
export class PortEntryComponent implements OnInit {
  portOfEntry = []
  constructor(private service: RestService) { }

  ngOnInit() {
    this.service.getPortEntry().subscribe((res: any) => {
      console.log(res);
      this.portOfEntry = res;
    })
  }

}
