<div class="box-cover">
    <div class="header-pop-help">
        <p class="header-pop-text">Tourist Visa</p>
    </div>
    <div class="pop-help container">
        <div class="box-card">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8">
                <div class="card-require">
                    <p class="require-text" style="text-align: center;">{{'Required Information'.toUpperCase()}}</p>
                    <div style="display: flex;margin-bottom: -15px; padding: 0;">
                        <img class="icons" src="../../../assets/images/boarding-pass.png">
                        <p class="text-style" translate="help_1"></p>
                    </div>
                    <div style="display: flex; margin-bottom: -15px; padding: 0;">
                        <img class="icons" src="../../../assets/images/profile.png">
                        <p class="text-style" translate="help_2"></p>
                    </div>
    
                    <div style="display: flex; margin-bottom: -15px; padding: 0;">
                        <img class="icons" src="/assets/images/curriculum-vitae.png">
                        <p class="text-style" translate="help_3"></p>
                    </div>
                    <div style="display: flex; margin-bottom: -15px; padding: 0;">
                        <img class="icons" src="/assets/images/visa.png">
                        <p class="text-style" translate="help_4"></p>
                    </div>
                    <div style="display: flex; margin-bottom: -10px;padding: 0;">
                        <img class="icons" src="/assets/images/info.png">
                        <p class="text-style" translate="help_5"></p>
                    </div>
                    
                    <div style="display: flex;margin-bottom: -10px; padding: 0;">
                        <img class="icons" src="/assets/images/correct.png" alt="">
                        <p class="text-style" translate="help_6">
                        </p>
                    </div>
                    <div style="display: flex;margin-bottom: -10px; padding: 0;">
                        <img class="icons" src="/assets/images/correct.png" alt="">
                        <p class="text-style" translate="help_7">
                    </div>
                     <div style="display: flex;margin-bottom: -15px; padding: 0;">
                        <img class="icons-1" src="/assets/images/clock.png" alt="">
                        <p class="text-style" translate="help_8">
                        </p>
                    </div>
                    <div style="display: flex;margin-bottom: -15px; padding: 0;">
                        <img class="icons-2" src="/assets/images/information.png" alt="">
                        <p class="text-style-2" translate="help_9">
                        </p>
                    </div>
                </div> 
            </div>

            <div class="preview-form col-sm-12 col-md-12 col-lg-12 col-xl-3">
                <div style="display: flex;justify-content: center;align-items: center;padding: 15px;">
                    <img class="image-form img-fluid" src="/assets/images/form-preview-en.png">
                </div>
                <div class="sample-btn">
                    <button class="btn-form">
                        <a href="/assets/images/form-preview-en.png" target="_blank">
                            Sample Applicaiton Form
                        </a>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>