import { Component, OnInit, ViewChild, ElementRef, Renderer2, HostListener, NgZone, Input, OnChanges, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
// import 'tippy.js/dist/tippy.css';
// import '../../../../node_modules/tippy.js/dist/tippy.css'
// import '../../../../node_modules/tippy.js/themes/light.css'
// import { followCursor } from 'tippy.js';

@Component({
  selector: 'app-lao-map',
  templateUrl: './lao-map.component.html',
  styleUrls: ['./lao-map.component.css']
})
export class LaoMapComponent implements OnInit {
  @Input() selectedCountryPass: string;
  @Input() selectedCountryCiti: string;
  dataFormLocalStorage = {
    countryCity: null,
    countryPassport: null,
  };

  ngOnChanges(changes: SimpleChanges) {
    this.ngZone.runOutsideAngular(() => {
      if (changes.selectedCountryPass) {
        console.log("Selected Country Passport:", changes.selectedCountryPass.currentValue);
        this.selectedCountryPass = changes.selectedCountryPass.currentValue;
        const previousData: any = JSON.parse(localStorage.getItem('previousData'));
        this.countryPassportLocal = previousData.countryPassport;
        this.countryCityLocal = previousData.countryCity;
      }
      if (changes.selectedCountryCiti) {
        console.log("Selected Country City:", changes.selectedCountryCiti.currentValue);
        this.selectedCountryCiti = changes.selectedCountryCiti.currentValue;
        const previousData: any = JSON.parse(localStorage.getItem('previousData'));
        this.countryPassportLocal = previousData.countryPassport;
        this.countryCityLocal = previousData.countryCity;
      }
    });
  }

  @ViewChild('tippyTemplate', { static: true }) tippyTemplate: ElementRef;
  tippyProps: NgxTippyProps = {
    theme: 'light',
    // trigger: 'click mouseenter',
    trigger: 'mouseenter',
    allowHTML: true,
    arrow: false,
    // hideOnClick: false,
    duration: [500, 0],
    // followCursor: true,
    // plugins: [followCursor],
  };

  // tippyPropsEx8: NgxTippyProps = { ...this.tippyProps, placement: "bottom" };

  @ViewChild('tooltip', { static: true }) tooltip: ElementRef;

  province: string;
  description: string;
  countryPassportLocal: string;
  countryCityLocal: string;
  width: number;


  constructor(private config: NgbTooltipConfig, private renderer: Renderer2, private ngZone: NgZone, private cdr: ChangeDetectorRef) {
    // config.placement = 'top';
    // this.detectScreenSize();
    // this.width = window.innerWidth;
    // if (this.width <= 979) {
    //   config.triggers = 'click'
    // }
    config.container = 'body';
  }


  // @HostListener("window:resize", [])
  // public onResize() {
  //   this.detectScreenSize();
  // }

  // public ngAfterViewInit() {
  //   this.detectScreenSize();
  // }

  // private detectScreenSize() {
  //   // const height = window.innerHeight;
  //   this.width = window.innerWidth;

  //   // if (this.width <= 979) {
  //   //   this.config.triggers = 'click'
  //   // }
  // }

  ngOnInit() {
    const previousData: any = JSON.parse(localStorage.getItem('previousData'));
    this.countryPassportLocal = previousData.countryPassport;
    this.countryCityLocal = previousData.countryCity;
    console.log("countryPassportLocal:", this.countryPassportLocal);
    console.log("countryCityLocal:", this.countryCityLocal);

    this.width = window.innerWidth;


    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('resize', this.onResize);
    });
    this.tippyProps.content = this.tippyTemplate.nativeElement;
    // this.tippyProps.content = this.tippyTemplate.nativeElement.innerHTML;

  }

  @HostListener('window:resize')
  onResize() {
    this.ngZone.run(() => {
      this.width = window.innerWidth;
    });
  }

  public mouseEnter($event, data) {
    const circle = $event.target as HTMLElement;
    const coordinates = circle.getBoundingClientRect();
    const x = `${coordinates.left + 20}px`;
    const y = `${coordinates.top + 20}px`;
    this.renderer.setStyle(this.tooltip.nativeElement, 'left', x);
    this.renderer.setStyle(this.tooltip.nativeElement, 'top', y);
    this.renderer.setStyle(this.tooltip.nativeElement, 'font-size', '50px');
    this.renderer.setStyle(this.tooltip.nativeElement, 'position', 'fixed');
    this.renderer.setStyle(this.tooltip.nativeElement, 'display', 'block');
    this.renderer.setProperty(
      this.tooltip.nativeElement,
      'innerHTML',
      `<p style="color: yellow;font-size: 25px">${data}</b>`
    );
  }

  public mouseLeave($event): void {
    this.renderer.setProperty(this.tooltip.nativeElement, 'innerHTML', '');
    this.renderer.setStyle(this.tooltip.nativeElement, 'display', 'none');
  }

  public over_state(value, value2) {
    this.province = value;
    this.description = value2;
  }

  path1Styles: { [key: string]: string } = { opacity: '1' };
  path2Styles: { [key: string]: string } = { opacity: '1' };

  onMouseOver(className: string) {
    this.path2Styles.opacity = '0';
    this.renderer.addClass(document.querySelector(`.${className}`), 'hover');
  }

  onMouseOut(className: string) {
    this.path2Styles.opacity = '1';
    this.renderer.removeClass(document.querySelector(`.${className}`), 'hover');
  }

  event = {
    firstEvent: {
      name: 'Event 1',
      startDate: new Date(),
      endDate: new Date()
    },
    secondEvent: {
      name: 'Event 2',
      startDate: new Date(),
      endDate: new Date()
    }
  };

  getTooltipText() {
    return `Name: ${this.event.firstEvent.name}
    Start: ${this.event.firstEvent.startDate.toLocaleString()}
    End: ${this.event.firstEvent.endDate.toLocaleString()}
    &#13;XXXXXXXXXXXX
    `;
  }
}