import * as tslib_1 from "tslib";
import { OnInit, AfterViewInit, } from "@angular/core";
import { environment } from "src/environments/environment";
import * as $ from "jquery";
import offside from "offside-js";
import { isPlatformBrowser } from "@angular/common";
var NavComponent = /** @class */ (function () {
    // NavSelectedLang: string;
    // MySelectedLang: string;
    function NavComponent(app, formDataService, rest, eventEmitterService, countInCart, router, activatedRoute, platformId) {
        this.app = app;
        this.formDataService = formDataService;
        this.rest = rest;
        this.eventEmitterService = eventEmitterService;
        this.countInCart = countInCart;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.countCart = 0;
        this.haveToken = false;
        this.agentToken = false;
        this.showFoundApp = false;
        // this.MySelectedLang = app.SelectedLang;
        // this.button_lang = app.SelectedLang;
        // this.NavSelectedLang = app.SelectedLang;
        this.button_lang = app.SelectedLang;
        this.isBrowser = isPlatformBrowser(platformId);
    }
    NavComponent.prototype.switchLanguage = function (language) {
        // this.NavSelectedLang = language;
        // this.MySelectedLang = language;
        this.button_lang = language;
        this.app.switchLanguage(language);
        if (this.router.url === "/term") {
            this.TermConditionsComponentFunction(language);
        }
        else if (this.router.url === "/faq") {
            this.FAQComponentFunction(language);
        }
        else if (this.router.url === "/info") {
            this.InfoComponentFunction(language);
        }
        else if (this.router.url === "/about") {
            this.AboutComponentFunction(language);
        }
        else if (this.activatedRoute.snapshot.url[0].path === "article") {
            this.ArticleComponentFunction(language);
        }
    };
    NavComponent.prototype.ngOnInit = function () {
        this.getLanguage();
        if (localStorage.getItem('previousData') !== null && localStorage.getItem('localAuthenInfo') !== null) {
            this.showFoundApp = true;
        }
        this.person = this.formDataService.getFormData();
        if (localStorage.getItem(environment.localAuthenInfo) != null) {
            var authInfo = JSON.parse(localStorage.getItem(environment.localAuthenInfo));
            if (authInfo) {
                if (authInfo.token) {
                    this.haveToken = true;
                    this.getCountCart();
                }
            }
        }
        // Check agent login
        if (localStorage.getItem(environment.agentAuthenInfo) != null) {
            var authInfo = JSON.parse(localStorage.getItem(environment.agentAuthenInfo));
            if (authInfo) {
                if (authInfo.token) {
                    this.agentToken = true;
                }
            }
        }
        if (this.isBrowser) {
            offside(".navbar-menu-mobile-wrapper", {
                buttonsSelector: ".navbar-menu-toggle",
                slidingSide: "right",
            });
        }
        // THIS MAKE INPUT CLICKABLE ON MOBILE
        function toggleInputClickableIfOverlayByLogo(width) {
            var logoEle = $(".navbar-evisa-logo");
            if (width >= 1800) {
                logoEle.removeClass("child-clickable");
            }
            else {
                logoEle.addClass("child-clickable");
            }
        }
        if (this.router.url === "/application" ||
            this.router.url.split("/")[1] === "edit-application") {
            $(document).ready(function () {
                return tslib_1.__awaiter(this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        toggleInputClickableIfOverlayByLogo($(this.window).width());
                        return [2 /*return*/];
                    });
                });
            });
        }
        if (this.router.url === "/application" ||
            this.router.url.split("/")[1] === "edit-application") {
            $(window).on("resize", function () {
                toggleInputClickableIfOverlayByLogo($(this).width());
            });
        }
    };
    NavComponent.prototype.ngAfterViewInit = function () { };
    NavComponent.prototype.getCountCart = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.countInCart.funcCountCart()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    NavComponent.prototype.TermConditionsComponentFunction = function (language) {
        this.eventEmitterService.onTermConditionComponentButtonClick(language);
    };
    NavComponent.prototype.FAQComponentFunction = function (language) {
        this.eventEmitterService.onFAQComponentButtonClick(language);
    };
    NavComponent.prototype.InfoComponentFunction = function (language) {
        this.eventEmitterService.onInfoComponentButtonClick(language);
    };
    NavComponent.prototype.AboutComponentFunction = function (language) {
        this.eventEmitterService.onAboutComponentButtonClick(language);
    };
    NavComponent.prototype.ArticleComponentFunction = function (language) {
        this.eventEmitterService.onArticleComponentButtonClick(language);
    };
    NavComponent.prototype.getLanguage = function () {
        var _this = this;
        this.rest.getLanguage().subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, result];
                    case 1:
                        _a.mLanguage = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    NavComponent.prototype.goIndex = function () {
        // offside('.navbar-menu-mobile-wrapper', {
        //   buttonsSelector: '.navbar-menu-toggle',
        //   slidingSide: 'right'
        // });
        this.router.navigate(["/index"], { replaceUrl: true });
    };
    NavComponent.prototype.logout = function () {
        localStorage.removeItem(environment.localAuthenInfo);
        localStorage.removeItem(environment.agentAuthenInfo);
        localStorage.removeItem("confirm");
        this.agentToken = false;
        this.router.navigate(["/login"]);
    };
    return NavComponent;
}());
export { NavComponent };
