import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
var VerifyComponent = /** @class */ (function () {
    function VerifyComponent(router, rest, route) {
        this.router = router;
        this.rest = rest;
        this.route = route;
        this.isWrong = false;
        this.isTokenExpired = false;
    }
    VerifyComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.verifyToken(params["id"]);
                return [2 /*return*/];
            });
        }); });
    };
    VerifyComponent.prototype.verifyToken = function (credential) {
        var _this = this;
        this.rest.verifyToken(credential).subscribe(function (result) {
            if (!result["auth"] &&
                result.message === "Failed to authenticate token.") {
                _this.isWrong = true;
                _this.isTokenExpired = false;
            }
            if (!result["auth"] && result.message === "TokenExpired") {
                _this.isTokenExpired = true;
                _this.isWrong = false;
                localStorage.removeItem(environment.localAuthenInfo);
            }
            if (!result["responseStatus"]) {
                _this.isWrong = true;
                _this.isTokenExpired = false;
                localStorage.removeItem(environment.localAuthenInfo);
            }
            else {
                localStorage.setItem(environment.localAuthenInfo, JSON.stringify(result));
                _this.router.navigate(["/application"]);
            }
        }, function (error) {
            console.error(error);
            _this.isWrong = false;
            localStorage.removeItem(environment.localAuthenInfo);
            _this.router.navigate(["/apply"], { replaceUrl: true });
        });
    };
    return VerifyComponent;
}());
export { VerifyComponent };
