/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./port-entry.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../nav/nav.component.ngfactory";
import * as i3 from "../nav/nav.component";
import * as i4 from "../../app.component";
import * as i5 from "../../services/form-data.service";
import * as i6 from "../../services/rest.service";
import * as i7 from "../../services/event-emitter.service";
import * as i8 from "../../services/count-cart.service";
import * as i9 from "@angular/router";
import * as i10 from "@angular/common";
import * as i11 from "../lao-map/lao-map.component.ngfactory";
import * as i12 from "../lao-map/lao-map.component";
import * as i13 from "@ng-bootstrap/ng-bootstrap";
import * as i14 from "../footer/footer.component.ngfactory";
import * as i15 from "../footer/footer.component";
import * as i16 from "./port-entry.component";
var styles_PortEntryComponent = [i0.styles];
var RenderType_PortEntryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PortEntryComponent, data: {} });
export { RenderType_PortEntryComponent as RenderType_PortEntryComponent };
function View_PortEntryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["style", "width: 35px; height: 35px; padding: 5px;"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ". \u00A0 ", " "]))], null, function (_ck, _v) { var currVal_0 = (_v.context.$implicit.port_of_entry_name.includes("Airport") ? "/assets/images/plane-icon.png" : (_v.context.$implicit.port_of_entry_name.includes("Bridge") ? "/assets/images/bridge-icon.png" : (_v.context.$implicit.port_of_entry_name.includes("Checkpoint") ? "/assets/images/road-icon.png" : "/assets/images/train-icon.png"))); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_v.context.index + 1); var currVal_2 = _v.context.$implicit.port_of_entry_name; _ck(_v, 2, 0, currVal_1, currVal_2); }); }
export function View_PortEntryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-nav", [], null, null, null, i2.View_NavComponent_0, i2.RenderType_NavComponent)), i1.ɵdid(1, 4308992, null, 0, i3.NavComponent, [i4.AppComponent, i5.FormDataService, i6.RestService, i7.EventEmitterService, i8.CountCartService, i9.Router, i9.ActivatedRoute, i1.PLATFORM_ID], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 16, "div", [["class", "cover-box row col-12 justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "col-sm-12 col-lg-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "portEntry col-sm-12 col-lg-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 9, "ul", [["style", "list-style: none;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Port of Entry"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["eVisa can be used to enter through these following designated ports:"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PortEntryComponent_1)), i1.ɵdid(14, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "col-sm-12 col-lg-5"], ["style", "height: 500px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "app-lao-map", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_LaoMapComponent_0, i11.RenderType_LaoMapComponent)), i1.ɵdid(17, 638976, null, 0, i12.LaoMapComponent, [i13.NgbTooltipConfig, i1.Renderer2, i1.NgZone, i1.ChangeDetectorRef], { selectedCountryPass: [0, "selectedCountryPass"], selectedCountryCiti: [1, "selectedCountryCiti"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 0, "div", [["class", "col-sm-12 col-lg-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "app-footer", [], null, null, null, i14.View_FooterComponent_0, i14.RenderType_FooterComponent)), i1.ɵdid(20, 114688, null, 0, i15.FooterComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.portOfEntry; _ck(_v, 14, 0, currVal_0); var currVal_1 = null; var currVal_2 = null; _ck(_v, 17, 0, currVal_1, currVal_2); _ck(_v, 20, 0); }, null); }
export function View_PortEntryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-port-entry", [], null, null, null, View_PortEntryComponent_0, RenderType_PortEntryComponent)), i1.ɵdid(1, 114688, null, 0, i16.PortEntryComponent, [i6.RestService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PortEntryComponentNgFactory = i1.ɵccf("app-port-entry", i16.PortEntryComponent, View_PortEntryComponent_Host_0, {}, {}, []);
export { PortEntryComponentNgFactory as PortEntryComponentNgFactory };
