import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { NewApplyComponent } from "../new-apply/new-apply.component";

@Component({
  selector: "app-popup-help",
  templateUrl: "./popup-help.component.html",
  styleUrls: ["./popup-help.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class PopupHelpComponent implements OnInit {
  refData: any;
  constructor(
    public dialogRef: MatDialogRef<PopupHelpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.refData = data;
  }

  ngOnInit() {
    console.log(this.refData);
  }
}
