<app-nav></app-nav>

<div class="cover-box row col-12 justify-content-between">
    <div class="col-sm-12 col-lg-1"></div>
    <div class="portEntry col-sm-12 col-lg-5">
        <ul style="list-style: none;" >
            <li>
                <h4><b>Port of Entry</b></h4>
            </li>
            <li>
                <p>eVisa can be used to enter through these following designated ports:</p>
            </li>
            <!-- <li *ngFor="let item of portOfEntry,let i = index" style="margin-bottom: 10px;"> 
                {{ i + 1}}. &nbsp;&nbsp;&nbsp; {{item.port_of_entry_name}}
            </li> -->
            <li *ngFor="let item of portOfEntry; let idx = index">
                <img
                  [src]="item.port_of_entry_name.includes('Airport') ? '/assets/images/plane-icon.png' : item.port_of_entry_name.includes('Bridge') ? '/assets/images/bridge-icon.png' : item.port_of_entry_name.includes('Checkpoint') ? '/assets/images/road-icon.png' : '/assets/images/train-icon.png'"
                  style="width: 35px; height: 35px; padding: 5px;">
                {{ idx + 1 }}. &nbsp; {{ item.port_of_entry_name }}
              </li>
        </ul>
    </div>
    <div class="col-sm-12 col-lg-5" style="height: 500px;">
        <app-lao-map [selectedCountryPass]="null" [selectedCountryCiti]="null"></app-lao-map>
    
    </div>
    <div class="col-sm-12 col-lg-1"></div>

</div>


<app-footer></app-footer>
