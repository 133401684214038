<app-nav></app-nav>

<!-- Maintainer HTML -->
<div *ngIf="underMaintainance" style="margin-top: 100px;">

  <div style="display: flex; justify-content: center;align-items: center;">
    <img src="../../../assets/images/warning_sign.png" style="width: 80px;" alt="">
  </div>
  <h4 style="text-align: center;font-weight: bold; margin: 24px 16px 24px 16px;"> We are currently under maintenance.
  </h4>

  <p style="text-align: center;font-weight: bold; margin: 24px 16px 24px 16px;">You should be able to apply for an eVisa
    in the next 30 minutes. Thank you for your patience and understanding.
    Please check again shortly.</p>

  <h5 style="text-align: center;font-weight: bold; margin: 24px 16px 24px 16px;">We apologize for the inconvenience.
  </h5>
  <div class="header" style="display: flex; justify-content: center;align-items: center;">
    <img src="../../../assets/images/ppls_maintain.jpeg" style="width: 80%; max-width: 500px;" alt="">
  </div>


</div>


<!-- Maintainer HTML -->

<main class="page page-security-verification" #bgColor *ngIf="!underMaintainance">
  <div class="container content-section" *ngIf="!isSuccess">
    <!-- <div class="row" *ngIf="showFoundPreApp" style="margin-top: 100px;">
      <div class="col-md-12 text-center">
        <div style="display: flex;justify-content: center; align-items: center;">
          <h5 translate="help_10" style="margin-right: 5px;">
          </h5>
          <button class="btn-form" (click)="clickShowFormEmail()">
            <h5>
              <a class="text-primary" translate="click_here"></a>
            </h5>
           </button>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-center" *ngIf="showInputEmail">
        <h6 translate="please_type"> </h6> &nbsp; {{maskedUserEmail}}

      </div>
      <div class="col-md-12 d-flex justify-content-center my-2" *ngIf="showInputEmail">
        <input class="mail-input form-control form-control-lg" type="text" placeholder="example@gmail.com"
          autocomplete="off" style="border-radius: 20px; height: 40px;text-align: center;width: 350px;"
          [(ngModel)]="inputEmail">
      </div>
      <div class="col-md-12 d-flex justify-content-center my-2 text-danger" *ngIf="formEmailChecked">
        <h6>
          Email dose not match!
        </h6>
      </div>

      <div class="col-md-12 d-flex justify-content-center" *ngIf="showInputEmail">
        <button class="btn btn-primary" (click)="clickProcess()">Countinue</button>
      </div>
    </div> -->
    <h1 class="content-section-title text-center text-uppercase" style="margin-top: 140px" #secuityHeader
      translate="security_verication">

      Security verification
    </h1>
    <br />
    <div class="row">
      <div class="col-md-6 mx-auto position-form">
        <form (ngSubmit)="onSubmitVerification(verifyForm)" [formGroup]="verifyForm">
          <div class="row" *ngIf="!openModal">
            <div class="col-12">
              <div class="form-group">
                <label class="label" translate="cop" for="verify_country_passport">Country of Passport <span
                    class="text-danger">*</span></label>
                <select formControlName="country_passport" (focus)="onFocus($event)" (blur)="onBlur($event)" #cop
                  (change)="onCountryOfPassportChange($event.target.value, $event)" required
                  [(ngModel)]="countryOfPassport" name="countryOfPassport" id="verify_country_passport"
                  class="form-control" [ngClass]="{
                    'is-invalid':
                      verifyForm.controls['country_passport'].touched &&
                      verifyForm.controls['country_passport'].errors
                  }">
                  <option value="" translate>-- Please Select --</option>
                  <option *ngFor="let item of mDataArrayCountry" [value]="item.country_id"
                    [selected]="selectedCountry == item.country_id">
                    {{ item.country_name }}
                  </option>
                </select>
                <div *ngIf="
                    verifyForm.controls['country_passport'].touched &&
                    verifyForm.controls['country_passport'].errors
                  " class="invalid-feedback">
                  <div *ngIf="
                      verifyForm.controls['country_passport'].errors.required
                    ">
                    Country of Passport is required
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="label" translate>Type of Travel Document/Passport<span
                    class="text-danger">*</span></label>
                <select [ngClass]="{
                    'is-invalid':
                      verifyForm.controls['passport_type'].touched &&
                      verifyForm.controls['passport_type'].errors
                  }" required name="passport_type" formControlName="passport_type" [(ngModel)]="passportType"
                  (focus)="onFocus($event)" (blur)="onBlur($event)" id="verify_passport_type" class="form-control"
                  (change)="checkPassportType($event)">
                  <option value="" translate>-- Please Select --</option>
                  <option value="ordinary">Ordinary/普通护照</option>
                  <option value="diplomatic">Diplomatic/外交护照</option>
                  <option value="service">
                    Service/Special/Official/公务护照/特殊护照
                  </option>
                  <option value="travel">
                    Travel Document for Stateless person/无国籍人士的旅行证件
                  </option>
                </select>
                <div *ngIf="
                    verifyForm.controls['passport_type'].touched &&
                    verifyForm.controls['passport_type'].errors
                  " class="invalid-feedback">
                  <div *ngIf="verifyForm.controls['passport_type'].errors.required" translate="passport_type_require">
                  </div>
                </div>
                <div translate="please_noted" class="alert alert-danger" role="alert" *ngIf="
                    passportType !== 'ordinary' && passportType !== ''
                  ">
                  Please noted that only ordinary passport is applicable to
                  apply for eVisa
                </div>
              </div>

              <div class="form-group">
                <label class="label" translate="Email">Email <span class="text-danger">*</span></label>
                <input autocomplete="off" id="verify_client_email" type="email" [(ngModel)]="clientEmail" [ngClass]="{
                    'is-invalid':
                      verifyForm.controls['client_email'].touched &&
                      verifyForm.controls['client_email'].errors
                  }" name="client_email" required [placeholder]="'Email' | translate" formControlName="client_email"
                  class="form-control form-control-lg" />
                <div class="invalid-feedback">
                  <div *ngIf="verifyForm.controls['client_email'].errors?.pattern">
                    Email is invalid
                  </div>
                  <div *ngIf="
                      verifyForm.controls['client_email'].touched &&
                      verifyForm.controls['client_email'].errors
                    ">
                    <div *ngIf="
                        verifyForm.controls['client_email'].errors.required
                      " translate="Email is required">
                      Email is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="border img-rounded p-2 captcha mb-2">
                <img *ngIf="captchaImage" [src]="captchaImage" alt="captcha" />
                &nbsp;
                <i class="fa fa-refresh" (click)="getImageFromService()" style="cursor: pointer; color: #1a77ba"></i>
              </div>
              <div class="form-group">
                <label class="label" translate="Verify Code">Verify Code <span class="text-danger">*</span></label>
                <input autocomplete="off" (keyup)="message = ''" type="text" pattern="[0-9]+" maxlength="6"
                  id="verify_code" required [ngClass]="{
                    'is-invalid':
                      verifyForm.controls['verify_code'].touched &&
                      verifyForm.controls['verify_code'].errors
                  }" [(ngModel)]="verifyCode" [placeholder]="'Enter security code' | translate" name="verify_code"
                  formControlName="verify_code" class="form-control form-control-lg" id="verify_code" />
                <div *ngIf="
                    (verifyForm.controls['verify_code'].touched &&
                      verifyForm.controls['verify_code'].errors) ||
                    this.isNotSuccess
                  " class="invalid-feedback">
                  <div *ngIf="verifyForm.controls['verify_code'].errors?.required"
                    translate="Security code is required">
                    Security code is required
                  </div>
                </div>
                <div style="font-size: 80%" class="text-danger" *ngIf="this.isNotSuccess">
                  {{ this.message }}
                </div>
              </div>
            </div>
          </div>



          <div class="row">


            <!-- <div class="col-6">
              <Button class="btn-form"><a
                  [href]="selectLanguage == 'en' ? '/assets/images/form-preview-en.png' : '/assets/images/form-preview-cn.png'"
                  target="_blank">Need some
                  help?</a></Button>
            </div> -->

            <div class="col-6">
              <Button class="btn-form" (click)="openModalHelp()"><a>
                  Need some
                  help?
                </a></Button>
            </div>

            <div class="col-6">
              <div class="form-group" *ngIf="!openModal">
                <button type="submit"
                  [disabled]="!clientEmail || !verifyCode || passportType !== 'ordinary' || verifyForm.controls['client_email'].errors?.pattern"
                  class="btn btn-primary pull-right" translate="Next">
                  Next
                </button>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>



  <div class="container content-section" *ngIf="isSuccess && VerifyType == 2">
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-4" style="margin-top: 140px">
        <h1 class="content-section-title text-center text-uppercase" translate>
          verification
        </h1>
        <div class="text-center">
          <p class="apply-p" translate="verify_1">
            To verify your email a PIN was sent to
          </p>
          <p class="apply-p">
            <b>{{ your_email }}</b>
          </p>
          <div>
            <p *ngIf="isMail.includes('gmail')">
              <button (click)="openInbox()" class="btn btn-light" style="border: 1px solid #b1b3b5">
                <img src="assets/images/gmail.png" height="20" />
                &nbsp;Open Gmail
              </button>
            </p>
            <p *ngIf="
                isMail.includes('myyahoo') ||
                isMail.includes('yahoo') ||
                isMail.includes('ymail')
              ">
              <button (click)="openInbox()" class="btn btn-light" style="border: 1px solid #b1b3b5">
                <img src="assets/images/yahoo.png" height="20" />
                &nbsp;Open Yahoo
              </button>
            </p>
            <p *ngIf="isMail.includes('outlook') || isMail.includes('hotmail')">
              <button (click)="openInbox()" class="btn btn-light" style="border: 1px solid #b1b3b5">
                <img src="assets/images/outlook.png" height="20" />
                &nbsp;Open Outlook
              </button>
            </p>
            <p *ngIf="isMail.split('.')[0].includes('qq')">
              <button (click)="openInbox()" class="btn btn-light" style="border: 1px solid #b1b3b5">
                <img src="assets/images/qq.png" height="20" />
                &nbsp;Open QQ Mail
              </button>
            </p>
          </div>
          <p class="apply-p" translate="verify_2">
            Please check your inbox and enter the PIN below.
          </p>

          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
              <div class="mt-4">
                <form #pinForm="ngForm" id="pinForm" (ngSubmit)="pinForm.form.valid && verifyPin(pinForm)">
                  <div class="d-flex justify-content-between">
                    <input autocomplete="off" (ngModelChange)="model_pin.pin1 = $event"
                      (keyup)="onKeyUp($event, maxLength, 1)" numeric numericType="number" maxlength="1" style="
                        width: 64px !important;
                        border-radius: 10px !important;
                      " type="text" name="pin1" id="pin1" class="form-control form-control-lg text-center" required
                      [(ngModel)]="pin1" #pin1s="ngModel" [ngClass]="{
                        'is-invalid': pinForm.submitted && pin1s.invalid
                      }" />
                    <input (ngModelChange)="model_pin.pin2 = $event" autocomplete="off"
                      (keyup)="onKeyUp($event, maxLength, 2)" numeric numericType="number" maxlength="1" style="
                        width: 64px !important;
                        border-radius: 10px !important;
                      " type="text" name="pin2" id="pin2" class="form-control form-control-lg text-center" required
                      [(ngModel)]="pin2" #pin2s="ngModel" [ngClass]="{
                        'is-invalid': pinForm.submitted && pin2s.invalid
                      }" />
                    <input (ngModelChange)="model_pin.pin3 = $event" autocomplete="off"
                      (keyup)="onKeyUp($event, maxLength, 3)" numeric numericType="number" maxlength="1" style="
                        width: 64px !important;
                        border-radius: 10px !important;
                      " type="text" name="pin3" id="pin3" class="form-control form-control-lg text-center" required
                      [(ngModel)]="pin3" #pin3s="ngModel" [ngClass]="{
                        'is-invalid': pinForm.submitted && pin3s.invalid
                      }" />
                    <input autocomplete="off" (keyup)="onKeyUp($event, maxLength, 4)" numeric numericType="number"
                      maxlength="1" style="
                        width: 64px !important;
                        border-radius: 10px !important;
                      " type="text" name="pin4" id="pin4" class="form-control form-control-lg text-center" required
                      [(ngModel)]="pin4" #pin4s="ngModel" [ngClass]="{
                        'is-invalid': pinForm.submitted && pin4s.invalid
                      }" />
                  </div>
                  <div class="alert alert-danger mt-3" *ngIf="pin_isNotSuccess" role="alert">
                    {{ message_pin }}
                  </div>
                  <div class="mt-5">
                    <button type="submit" [disabled]="pinForm.form.invalid"
                      class="btn btn-blue btn-lg btn-block text-uppercase" translate>
                      verify <i class="fa fa-arrow-right"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-1"></div>
          </div>
        </div>
      </div>
      <div class="col-md-4"></div>
    </div>
  </div>
</main>
<ngx-spinner></ngx-spinner>
<div *ngIf="!underMaintainance && !openModal">
  <app-footer></app-footer>

</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h1 class="modal-title text-red text-center w-100" translate="notice">
      Notice
    </h1>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <p *ngIf="app.SelectedLang == 'en'" class="text-secondary">
      Citizens of
      <span class="font-weight-bold text-blue" style="font-size: 20px">{{
        citizens
        }}</span>
      are allowed to enter the Lao PDR without visa requirement for a maximum
      stay of
      <span class="text-blue font-weight-bold">{{ days }} days</span> per visit.
      <br />
    </p>
    <p *ngIf="app.SelectedLang == 'en'" class="text-secondary">
      Meanwhile, by obtaining an eVisa, visitors may stay in the Lao PDR up to
      30 days and the stay permit can be extended at the Immigration office in
      the Lao PDR before the expiry date.
    </p>
    <p *ngIf="app.SelectedLang == 'cn'" class="text-secondary">
      <span class="font-weight-bold text-blue" style="font-size: 20px">{{
        citizens
        }}</span>
      国家居民不需要申请赴老挝的签证，无须您可以免签证在老挝停留
      <span class="text-blue font-weight-bold">{{ days }} 天。</span>30 天
      后您必须离开老挝，并在您需要时再来老挝
    </p>
    <br />
    <!-- <p *ngIf="app.SelectedLang == 'en'" class="text-secondary">However, by obtaining an eVisa, you can stay in the Lao
      PDR up to 30 days and your stay permit can be extended at the Immigration office without having to leave the Lao
      PDR.</p> -->
    <p *ngIf="app.SelectedLang == 'cn'" class="text-secondary">
      但是，您如果申请了电子签证，可以在老挝停留30天，您无须离境就能向移民局申请延长在老挝的停留时间。
    </p>
    <p [innerHTML]="except_message"></p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')" translate>
      Close
    </button>
  </div>
</ng-template>