import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatRadioChange } from "@angular/material/radio";
import { CountCartService } from "src/app/services/count-cart.service";
import { RestService } from "src/app/services/rest.service";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { LOCAL_STORAGE } from "@ng-toolkit/universal";

@Component({
  selector: "app-paynow",
  templateUrl: "./paynow.component.html",
  styleUrls: ["./paynow.component.css"],
})
export class PaynowComponent implements OnInit {
  mPaymentMethod: Array<any> = [];
  model: any;
  @ViewChild("bcelForm", { static: false }) testFormEl: any;
  @Input() invoiceId: number;
  @Input() payFrom: string;
  @Output() mDataArrayPerson?: EventEmitter<Array<any>> = new EventEmitter();

  selected_payment_method: string = "";
  selected_payment_method_text: string = "";
  loading = false;
  isMaintainer: boolean = environment.maintainerWeb;

  constructor(
    private rest: RestService,
    private countCart: CountCartService,
    private route: Router,
    @Inject(LOCAL_STORAGE) private localStorage: any
  ) {}

  ngOnInit() {
    this.getPaymentMethods();
  }

  getPaymentMethods() {
    this.rest.getPaymentMethods().subscribe((result) => {
      this.mPaymentMethod = result;
    });
  }

  selectPaymentMethod(
    _: MatRadioChange,
    paymentType: string,
    paymentText: string
  ) {
    this.selected_payment_method = paymentType;
    this.selected_payment_method_text = paymentText;
  }

  proceedCheckOut() {
    if (!this.selected_payment_method) return;
    Swal.fire({
      title: "Payment Confirmation",
      text: `You are making the payment via ${this.selected_payment_method_text}`,
      type: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#1b75bb",
      cancelButtonText: "Cancel",
      confirmButtonText: "Pay",
    }).then((result) => {
      if (result.value) {
        this.localStorage.removeItem("previousData")
        this.localStorage.removeItem(environment.localAuthenInfo);
        // this.rest
        //   .updateUserSession({
        //     session_id: localStorage.getItem("session_id"),
        //     input_id: "paynowBtn",
        //     input_value: "click_check_out",
        //   })
        //   .subscribe();
        const data = {
          invoice_id: this.invoiceId,
          payment_method: this.selected_payment_method,
          pay_from: this.payFrom,
        };
        this.loading = true;
        this.rest.payNowV2Service(data).subscribe((result) => {
          this.loading = false;
          if (
            this.selected_payment_method == "CBS" ||
            this.selected_payment_method == "BCEL"
          ) {
            if (result["status"]) {
              if (
                result["status"] == "error" ||
                result["status"] == "notfound"
              ) {
                Swal.fire({
                  title: "Failed",
                  text: result.message || "Can not proceed to checkout!",
                  type: "warning",
                  showCancelButton: false,
                  confirmButtonColor: "#d33333",
                  confirmButtonText: "CLOSE",
                });
              } else if (result["status"] == "paid" && result["invoiceId"]) {
                const anchor = document.createElement("a");
                anchor.href = `${window.location.origin}/paysuccess/${result["invoiceId"]}`;
                anchor.click();
              }
            } else {
              this.model = result;
              setTimeout((_) => this.testFormEl.nativeElement.submit());
              if (this.payFrom == "cart") this.clearCart();
            }
          } else {
            if (result["success"] && result["data"]) {
              const anchor = document.createElement("a");
              anchor.href = result["data"]["payUrl"];
              anchor.click();
            } else if (result["url"]) {
              // if response is BCEL data
              this.model = result;
              setTimeout((_) => this.testFormEl.nativeElement.submit());
              if (this.payFrom == "cart") this.clearCart();
            } else {
              Swal.fire({
                title: "Failed",
                text: result.message,
                type: "warning",
                showCancelButton: false,
                confirmButtonColor: "#d33333",
                confirmButtonText: "CLOSE",
              });
            }
          }
        });
      }
    });
  }

  clearCart() {
    this.getPaymentMethods();
    this.rest.getPersonForPayment().subscribe((result) => {
      if (this.mDataArrayPerson) this.mDataArrayPerson.emit(result);
    });
    this.countCart.funcCountCart();
  }
}
