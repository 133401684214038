<app-nav></app-nav>
<div class="cover-container">
    <div class="cover-box">
        <div class="approval-letter">
            <ul style="list-style: none;" >
                <li>
                    <h4><b>eVisa Approval Letter</b></h4>
                </li>
                <li>
                    <p>The Lao eVisa Approval Letter is a document issued by the Lao Ministry of Foreign Affairs (MOFA) that allows you to enter the
                        Lao PDR for a specific time period. With this approval letter, you can obtain your eVisa sticker upon arrival at one of the
                        designated eVisa ports in the Lao PDR</p>
                </li>
                <li>
                    <p>
                        This letter is considered the key document for entering the Lao PDR via eVisa. Without the Lao eVisa approval letter, your entry
                        will be denied. To view a sample of the approval letter, <a href="/assets/images/approval_letter.png" target="_blank">click here</a>
                    </p>
                </li>
                <li>
                    <p>
                        At the arrival port, the authorities will verify the information on your approval letter with the details on your passport as part of
                        the immigration process.
                    </p>
                </li>
            </ul>
        </div>
    </div>
</div>


<app-footer></app-footer>
