<app-nav></app-nav>

<div style="margin-top: 200px; height: 70%; max-height: 700px;">

    <div class="col-md-12 text-center">
        <h5 translate="found_app">
        </h5>
        <h5 translate="found_app_2">
        </h5>
      </div>
      <div class="col-md-12 d-flex justify-content-center">
        <h6 translate="please_type"></h6>  &nbsp; {{maskedUserEmail}}
      </div>   
      <div class="col-md-12 d-flex justify-content-center my-2" >
        <input class="mail-input form-control form-control-lg" type=" text" placeholder="example@gmail.com"
          autocomplete="off" style="border-radius: 20px; height: 40px;text-align: center;width: 350px;" [(ngModel)]="inputEmail">
      </div>
      <div class="col-md-12 d-flex justify-content-center my-2 text-danger" *ngIf="formEmailChecked">
        <h6>
          Email dose not match!
        </h6>
      </div>
      <div class="col-md-12 d-flex justify-content-center" >
        <button class="btn btn-primary" style="border-radius: 10px;" (click)="clickProcess()">Countinue</button>
      </div>
  </div>

<app-footer></app-footer>
