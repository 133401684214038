import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { RestService } from "src/app/services/rest.service";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-verify",
  templateUrl: "./verify.component.html",
  styleUrls: ["./verify.component.css"],
})
export class VerifyComponent implements OnInit {
  constructor(
    private router: Router,
    private rest: RestService,
    private route: ActivatedRoute
  ) {}

  isWrong = false;
  isTokenExpired = false;

  ngOnInit() {
    this.route.params.subscribe(async (params) => {
      this.verifyToken(params["id"]);
    });
  }

  verifyToken(credential) {
    this.rest.verifyToken(credential).subscribe(
      (result) => {
        if (
          !result["auth"] &&
          result.message === "Failed to authenticate token."
        ) {
          this.isWrong = true;
          this.isTokenExpired = false;
        }
        if (!result["auth"] && result.message === "TokenExpired") {
          this.isTokenExpired = true;
          this.isWrong = false;
          localStorage.removeItem(environment.localAuthenInfo);
        }
        if (!result["responseStatus"]) {
          this.isWrong = true;
          this.isTokenExpired = false;
          localStorage.removeItem(environment.localAuthenInfo);
        } else {
          localStorage.setItem(
            environment.localAuthenInfo,
            JSON.stringify(result)
          );
          this.router.navigate(["/application"]);
        }
      },
      (error) => {
        console.error(error);
        this.isWrong = false;
        localStorage.removeItem(environment.localAuthenInfo);
        this.router.navigate(["/apply"], { replaceUrl: true });
      }
    );
  }
}
